'use client';

import { BlocksOne, BlocksTwo, NotFound, NotFoundOutline } from '@/components/svg-elements';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { useRouter } from 'nextjs-toploader/app';
import React from 'react';

const NotFoundRoot = () => {
  const router = useRouter();
  return (
    <div className={cn(
      'w-full flex-grow h-full px-2 z-10 flex items-center',
    )}>
      <div className="flex-wrap w-full px-4 mx-auto max-w-[530px] text-center">
        {/* 404 SVG */}
        <div className="mx-auto mb-5 text-center">
          <NotFound className='hidden dark:block w-full h-min max-w-[400px] mx-auto' />
          <NotFoundOutline className='dark:hidden w-full h-min max-w-[400px] mx-auto' />
        </div>

        <BlocksOne className="absolute left-0 bottom-0 z-[-1] hidden sm:block" />
        <BlocksTwo className="absolute top-0 right-0 z-[-1] hidden sm:block" />

        <div className='flex flex-col gap-1'>
          <h1 className='hidden'>
            Page Not Found
          </h1>
          <h2 className='mt-[-40] text-xl font-bold'>
            Hmm, this page is missing.
          </h2>
          <div className="text-sm text-gray-400 mb-2">
            The page you are looking for does not exist, or was moved.
          </div>
          <div className='flex flex-row flex-wrap gap-2 justify-center'>
            <Button className="text-white" onClick={() => {
              router.back();
            }}>
              Go Back
            </Button>
            <Link href="/">
              <Button className="text-white">
                Back Home
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>);
};

export default NotFoundRoot;
